@if (formGroup) {
  <div class="filter-node" data-cy="filter-node">
    @if (!isRoot) {
      <div class="connection-line"></div>
    }
    <div class="filter-node__delete">
      @if (!isRoot) {
        <ino-icon-button
          (click)="deleteSelf()"
          class="delete-button"
          icon="remove"
          data-cy="filter-node__delete"
          activated
          role="button"
        >
          <ino-tooltip
            [label]="'common:filterNode.delete' | i18next"
            placement="top"
            delay="300"
            colorScheme="light"
          ></ino-tooltip>
        </ino-icon-button>
      }
    </div>

    @if (typeControl?.value === FilterNodeType.Leaf) {
      <div
        class="filter-node__leaf"
        [ngTemplateOutlet]="filterCriteriaTemplate"
        [ngTemplateOutletContext]="{ $implicit: valueFormGroup }"
      ></div>
    }

    @if (typeControl?.value !== FilterNodeType.Leaf) {
      <div class="filter-node__group">
        <div class="filter-node__header">
          <div class="filter-node__condition">
            <ino-segment-group
              class="small dark"
              [value]="formGroup.controls.type.value"
              (valueChange)="changeCondition($any($event))"
            >
              <ino-segment-button [value]="FilterNodeType.And" data-cy="filter-node__and"
                >{{ FilterNodeType.And }}
              </ino-segment-button>
              <ino-segment-button [value]="FilterNodeType.Or" data-cy="filter-node__or"
                >{{ FilterNodeType.Or }}
              </ino-segment-button>
            </ino-segment-group>
          </div>
          <div class="filter-node__add">
            <ino-icon
              (click)="addNode(FilterNodeType.Leaf)"
              [clickable]="true"
              class="icon-button"
              data-cy="filter-node__addLeaf"
              src="assets/icons/add-filter.svg"
              role="button"
            >
              <ino-tooltip
                placement="top"
                [label]="'common:filterNode.addFilter' | i18next"
                delay="300"
                colorScheme="light"
              ></ino-tooltip>
            </ino-icon>
            <ino-icon
              (click)="addNode(FilterNodeType.And)"
              [clickable]="true"
              class="icon-button add-group"
              data-cy="filter-node__addGroup"
              src="assets/icons/add-group.svg"
              role="button"
            >
              <ino-tooltip
                placement="top"
                [label]="'common:filterNode.addGroup' | i18next"
                delay="300"
                colorScheme="light"
              ></ino-tooltip>
            </ino-icon>
          </div>
        </div>
        <div class="filter-node__group__content">
          @for (node of childrenFormArray?.controls; track $index) {
            <div class="child-node">
              <app-filter-node
                [formGroup]="node"
                (deleteButtonClicked)="deleteChild($index)"
                [filterCriteriaTemplate]="filterCriteriaTemplate"
              ></app-filter-node>
            </div>
          }
        </div>
      </div>
    }
  </div>
}
