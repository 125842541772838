export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  JSON: { input: any; output: any };
  YearMonth: { input: any; output: any };
};

export type Account = {
  __typename?: 'Account';
  defaultTeams?: Maybe<Array<Maybe<Team>>>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  login: Scalars['String']['output'];
  relatedEmployee?: Maybe<Employee>;
  roles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type AccountInput = {
  defaultTeamIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type Activity = {
  __typename?: 'Activity';
  aggregateId?: Maybe<Scalars['String']['output']>;
  aggregateType?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  parentAggregateId?: Maybe<Scalars['String']['output']>;
  recorded?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
};

export type AggregateSkill = {
  __typename?: 'AggregateSkill';
  /**  the count of !open (=closed, rejected, ...) opportunities this skill is assigned in the assignments within the opportunity */
  assignedClosedOpportunitiesCount?: Maybe<Scalars['Int']['output']>;
  /**  the count of employees this skill is assigned to, including inactive employees */
  assignedEmployeesCount?: Maybe<Scalars['Int']['output']>;
  /**  the count of open opportunities this skill is assigned in the assignments within the opportunity */
  assignedOpenOpportunitiesCount?: Maybe<Scalars['Int']['output']>;
  /**  employees or teams by which this skill is used */
  assignedTeams?: Maybe<Array<Team>>;
  /**  the timestamp of the skill creation */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /**  the description of the skill if present */
  description?: Maybe<Scalars['String']['output']>;
  /**  the name of the skill */
  name?: Maybe<Scalars['String']['output']>;
};

export type AvailabilityInput = {
  /**  minimal percentage of free capacity */
  freeCapacityPercentage?: InputMaybe<Scalars['Int']['input']>;
};

export enum BillingType {
  FlatRate = 'FLAT_RATE',
  TimeAndMaterial = 'TIME_AND_MATERIAL',
  Unknown = 'UNKNOWN',
}

export type Change = {
  __typename?: 'Change';
  attribute: Scalars['String']['output'];
  newValue?: Maybe<Scalars['String']['output']>;
  oldValue?: Maybe<Scalars['String']['output']>;
};

export enum CloseReason {
  CompetitorPreferred = 'COMPETITOR_PREFERRED',
  Custom = 'CUSTOM',
  Duplicate = 'DUPLICATE',
  InsufficientCapacities = 'INSUFFICIENT_CAPACITIES',
  MissingBudget = 'MISSING_BUDGET',
  MissingStrategicRelevance = 'MISSING_STRATEGIC_RELEVANCE',
  NoResponse = 'NO_RESPONSE',
  Price = 'PRICE',
  ProjectCancelled = 'PROJECT_CANCELLED',
}

export type Contact = {
  __typename?: 'Contact';
  department?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  salutation?: Maybe<SalutationEnum>;
  telephone?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  zepId?: Maybe<Scalars['String']['output']>;
};

export type ContactInput = {
  customerId: Scalars['ID']['input'];
  department?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  salutation?: InputMaybe<SalutationEnum>;
  telephone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export enum ContactType {
  Billing = 'BILLING',
  Professional = 'PROFESSIONAL',
  Technical = 'TECHNICAL',
}

export enum CriteriaOperator {
  Equals = 'EQUALS',
  Greater = 'GREATER',
  GreaterEqual = 'GREATER_EQUAL',
  In = 'IN',
  IsNull = 'IS_NULL',
  Less = 'LESS',
  LessEqual = 'LESS_EQUAL',
  Like = 'LIKE',
  NotEquals = 'NOT_EQUALS',
  NotIn = 'NOT_IN',
  NotNull = 'NOT_NULL',
}

export type CrmReference = {
  __typename?: 'CrmReference';
  id: Scalars['String']['output'];
  link?: Maybe<Scalars['String']['output']>;
  system: Scalars['String']['output'];
};

export type Customer = {
  __typename?: 'Customer';
  clientNumber?: Maybe<Scalars['String']['output']>;
  contacts?: Maybe<Array<Maybe<Contact>>>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CustomerInput = {
  clientNumber?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type DateRangeInput = {
  max?: InputMaybe<Scalars['Date']['input']>;
  min?: InputMaybe<Scalars['Date']['input']>;
};

export type Document = {
  __typename?: 'Document';
  context?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['JSON']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  schemaVersion?: Maybe<Scalars['Int']['output']>;
};

export type DocumentFilterInput = {
  context?: InputMaybe<Scalars['String']['input']>;
  schemaVersion?: InputMaybe<Scalars['Int']['input']>;
};

export type DocumentInput = {
  context: Scalars['String']['input'];
  data: Scalars['JSON']['input'];
  name: Scalars['String']['input'];
  schemaVersion: Scalars['Int']['input'];
};

export type Employee = {
  __typename?: 'Employee';
  /**  List of intervals for absences */
  absenceIntervals?: Maybe<Array<Maybe<EmployeeAbsenceInterval>>>;
  absences?: Maybe<Array<Maybe<EmployeeAbsence>>>;
  /**
   *  Availability statistics for a defined time range (max 90 days allowed).
   *  This data is only available for active and billable employees.
   */
  availability?: Maybe<EmployeeAvailabilityStatistics>;
  /** @deprecated Use `mostRelevantEmploymentPeriod` */
  closestEmploymentPeriod?: Maybe<EmploymentPeriod>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  employmentPeriods?: Maybe<Array<Maybe<EmploymentPeriod>>>;
  firstName: Scalars['String']['output'];
  forecastsHalfYear?: Maybe<Array<Maybe<EmployeeForecast>>>;
  id: Scalars['ID']['output'];
  internal?: Maybe<Scalars['Boolean']['output']>;
  labels?: Maybe<Array<Maybe<Label>>>;
  landline?: Maybe<Scalars['String']['output']>;
  lastModified?: Maybe<Scalars['DateTime']['output']>;
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  mobile?: Maybe<Scalars['String']['output']>;
  mostRelevantEmploymentPeriod?: Maybe<EmploymentPeriod>;
  opportunityAssignmentProposals?: Maybe<Array<Maybe<OpportunityAssignmentProposal>>>;
  opportunityAssignments?: Maybe<Array<Maybe<OpportunityAssignment>>>;
  projectAssignments?: Maybe<Array<Maybe<ProjectAssignment>>>;
  salesProfileGoogleDriveId?: Maybe<Scalars['String']['output']>;
  salesProfileLastModified?: Maybe<Scalars['DateTime']['output']>;
  skills?: Maybe<Array<Maybe<RatedSkill>>>;
  socialProfiles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status?: Maybe<EmployeeStatus>;
  topic?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

export type EmployeeAbsenceIntervalsArgs = {
  interval: IntervalInput;
};

export type EmployeeAbsencesArgs = {
  filter: DateRangeInput;
};

export type EmployeeAvailabilityArgs = {
  intervalInDays?: InputMaybe<Scalars['Int']['input']>;
};

export type EmployeeEmploymentPeriodsArgs = {
  interval?: InputMaybe<IntervalInput>;
};

export type EmployeeProjectAssignmentsArgs = {
  interval?: InputMaybe<IntervalInput>;
};

export type EmployeeAbsence = {
  __typename?: 'EmployeeAbsence';
  date?: Maybe<Scalars['Date']['output']>;
  hours?: Maybe<Scalars['Float']['output']>;
  workingDayRatio?: Maybe<Scalars['Int']['output']>;
};

/**  Interval of absences for employees including holidays */
export type EmployeeAbsenceInterval = {
  __typename?: 'EmployeeAbsenceInterval';
  end?: Maybe<Scalars['Date']['output']>;
  start?: Maybe<Scalars['Date']['output']>;
  /**  Percentage of time off on a working day relative to the hours defined by the employment period */
  workingDayRatio?: Maybe<Scalars['Float']['output']>;
};

/**  availability statistics for an employee */
export type EmployeeAvailabilityStatistics = {
  __typename?: 'EmployeeAvailabilityStatistics';
  /**  average free capacity percentage over the filter interval */
  averageFreeCapacityPercentage?: Maybe<Scalars['Float']['output']>;
  /**  today's free capacity percentage */
  currentFreeCapacityPercentage?: Maybe<Scalars['Float']['output']>;
  /**  first day with free capacity */
  firstIdleDay?: Maybe<Scalars['Date']['output']>;
  /**  true if employee is overplanned, meaning assigned with over 100% to multiple projects */
  isOverplanned?: Maybe<Scalars['Boolean']['output']>;
  /**  maximum free capacity percentage */
  maxFreeCapacityPercentage?: Maybe<Scalars['Float']['output']>;
};

export type EmployeeFilterCriteriaInput = {
  key: EmployeeFilterKeys;
  operator: CriteriaOperator;
  value: Scalars['String']['input'];
};

/**  Advanced Filter Inputs */
export enum EmployeeFilterKeys {
  /**  the availability must be specified by a string "<number of days>d<availability percentage>", e.g. "7d50" */
  Availability = 'AVAILABILITY',
  Email = 'EMAIL',
  EmploymentStatus = 'EMPLOYMENT_STATUS',
  FirstName = 'FIRST_NAME',
  Landline = 'LANDLINE',
  LastName = 'LAST_NAME',
  Location = 'LOCATION',
  Mobile = 'MOBILE',
  /**  the overplanned must be specified by a bool string "true" or "false" */
  Overplanned = 'OVERPLANNED',
  /**  the sales profile last modified filter input pattern. It either accepts a Date e.g. "2023-11-15" or an ISO-formatted ZonedDateTime like "2023-11-15T11:14:52Z". It is also possible to use duration regex like a number for time period, a letter describing what the time period is. Days(d), Weeks(w), Months(m), Years(y). Optionally a "+" symbol can be used for all of the above methods indicating that the employees with no profile should be in the returning list. e.g. "6m+" means we are looking for employees that have a profile older/recent than 6 months an also the employees with no profiles. */
  SalesProfileLastModified = 'SALES_PROFILE_LAST_MODIFIED',
  Skills = 'SKILLS',
  State = 'STATE',
  Team = 'TEAM',
  TeamLead = 'TEAM_LEAD',
  UserId = 'USER_ID',
}

export type EmployeeFilterNodeInput = {
  children?: InputMaybe<Array<InputMaybe<EmployeeFilterNodeInput>>>;
  type: FilterNodeType;
  value?: InputMaybe<EmployeeFilterCriteriaInput>;
};

export type EmployeeForecast = {
  __typename?: 'EmployeeForecast';
  availableHours: Scalars['Float']['output'];
  calculationTime?: Maybe<Scalars['DateTime']['output']>;
  employee?: Maybe<Employee>;
  forecasts?: Maybe<Array<Maybe<Forecast>>>;
  log?: Maybe<Scalars['String']['output']>;
  month: Scalars['YearMonth']['output'];
};

export type EmployeeForecastFilterInput = {
  period?: InputMaybe<YearMonthRangeInput>;
};

export type EmployeeInput = {
  emailPrivate?: InputMaybe<Scalars['String']['input']>;
  labelIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  receiveAlumniNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
  skills?: InputMaybe<Array<InputMaybe<RatedSkillInput>>>;
  socialProfiles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum EmployeeSearchField {
  Email = 'EMAIL',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  Phone = 'PHONE',
  UserId = 'USER_ID',
}

export type EmployeeSearchInput = {
  expression: Scalars['String']['input'];
  fields: Array<InputMaybe<EmployeeSearchField>>;
};

export enum EmployeeSortField {
  CreatedDate = 'CREATED_DATE',
  EmploymentStatus = 'EMPLOYMENT_STATUS',
  FirstName = 'FIRST_NAME',
  LastModifiedDate = 'LAST_MODIFIED_DATE',
  LastName = 'LAST_NAME',
  Location = 'LOCATION',
}

export type EmployeeSortInput = {
  field: EmployeeSortField;
  order?: InputMaybe<SortOrder>;
};

export enum EmployeeStatus {
  Active = 'ACTIVE',
  Future = 'FUTURE',
  Past = 'PAST',
  Unknown = 'UNKNOWN',
}

export type EmploymentPeriod = {
  __typename?: 'EmploymentPeriod';
  billable?: Maybe<Scalars['Boolean']['output']>;
  employmentStatus?: Maybe<EmploymentStatus>;
  from?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  location?: Maybe<Location>;
  team?: Maybe<Team>;
  teamLead?: Maybe<Employee>;
  to?: Maybe<Scalars['Date']['output']>;
  workingHoursFriday?: Maybe<Scalars['Float']['output']>;
  workingHoursMonday?: Maybe<Scalars['Float']['output']>;
  workingHoursSaturday?: Maybe<Scalars['Float']['output']>;
  workingHoursSunday?: Maybe<Scalars['Float']['output']>;
  workingHoursThursday?: Maybe<Scalars['Float']['output']>;
  workingHoursTuesday?: Maybe<Scalars['Float']['output']>;
  workingHoursWednesday?: Maybe<Scalars['Float']['output']>;
};

export type EmploymentStatus = {
  __typename?: 'EmploymentStatus';
  detail: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum FilterNodeType {
  And = 'AND',
  Leaf = 'LEAF',
  Or = 'OR',
}

export enum FilterOperator {
  And = 'AND',
  Or = 'OR',
}

export type Forecast = {
  __typename?: 'Forecast';
  busyHours?: Maybe<Scalars['Float']['output']>;
  calculationTime?: Maybe<Scalars['DateTime']['output']>;
  employee?: Maybe<Employee>;
  forecastable?: Maybe<Forecastable>;
  month: Scalars['YearMonth']['output'];
  rate?: Maybe<Scalars['Float']['output']>;
  revenue?: Maybe<Scalars['Float']['output']>;
};

export type ForecastFilterInput = {
  employeeId?: InputMaybe<Scalars['ID']['input']>;
  forecastable?: InputMaybe<ForecastableInput>;
  period?: InputMaybe<YearMonthRangeInput>;
};

export type ForecastReport = {
  __typename?: 'ForecastReport';
  calculationTime?: Maybe<Scalars['DateTime']['output']>;
  forecastableTypeRatios?: Maybe<Array<Maybe<ForecastableTypeRatio>>>;
  month: Scalars['YearMonth']['output'];
  nonBillableInvestment?: Maybe<Scalars['Float']['output']>;
  potentialRevenue?: Maybe<Scalars['Float']['output']>;
};

export type ForecastReportFilterInput = {
  period?: InputMaybe<YearMonthRangeInput>;
};

export type ForecastStats = {
  __typename?: 'ForecastStats';
  calculationTime?: Maybe<Scalars['DateTime']['output']>;
  from?: Maybe<Scalars['Date']['output']>;
  revenue?: Maybe<Scalars['Float']['output']>;
  revenueHint?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['Date']['output']>;
};

export type Forecastable = {
  __typename?: 'Forecastable';
  entity?: Maybe<ForecastableUnion>;
  type?: Maybe<ForecastableType>;
};

export type ForecastableInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  type: ForecastableType;
};

export enum ForecastableType {
  None = 'NONE',
  Opportunity = 'OPPORTUNITY',
  Project = 'PROJECT',
}

export type ForecastableTypeRatio = {
  __typename?: 'ForecastableTypeRatio';
  expectedRevenue?: Maybe<Scalars['Float']['output']>;
  forecastableType?: Maybe<ForecastableType>;
};

export type ForecastableUnion = Opportunity | Project;

export enum InformationClassification {
  Confidential = 'CONFIDENTIAL',
  Internal = 'INTERNAL',
  Restricted = 'RESTRICTED',
}

export type IntRangeInput = {
  max?: InputMaybe<Scalars['Int']['input']>;
  min?: InputMaybe<Scalars['Int']['input']>;
};

export type IntervalInput = {
  /**  the (inclusive) end date of the interval */
  end?: InputMaybe<Scalars['Date']['input']>;
  /**  the (inclusive) start date of the interval */
  start?: InputMaybe<Scalars['Date']['input']>;
};

export type Label = {
  __typename?: 'Label';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type LabelInput = {
  name: Scalars['String']['input'];
};

export type Link = {
  __typename?: 'Link';
  label: Scalars['String']['output'];
  type: LinkType;
  url: Scalars['String']['output'];
};

export enum LinkType {
  Custom = 'CUSTOM',
  System = 'SYSTEM',
}

export type Location = {
  __typename?: 'Location';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  closeOpportunity?: Maybe<Opportunity>;
  createContact?: Maybe<Contact>;
  createCustomer?: Maybe<Customer>;
  createDocument?: Maybe<Document>;
  createLabel?: Maybe<Label>;
  createOpportunity?: Maybe<Opportunity>;
  createOpportunityAssignment?: Maybe<OpportunityAssignment>;
  createOpportunityAssignmentProposal?: Maybe<OpportunityAssignmentProposal>;
  createSkill?: Maybe<Skill>;
  deleteDocument?: Maybe<Document>;
  deleteOpportunity?: Maybe<Opportunity>;
  deleteOpportunityAssignment?: Maybe<OpportunityAssignment>;
  deleteOpportunityAssignmentProposal?: Maybe<OpportunityAssignmentProposal>;
  deleteSkill?: Maybe<Skill>;
  markOpportunityAsWon?: Maybe<Opportunity>;
  mergeSkills?: Maybe<Skill>;
  reopenOpportunity?: Maybe<Opportunity>;
  requestProject?: Maybe<Scalars['Boolean']['output']>;
  updateAccount?: Maybe<Account>;
  updateContact?: Maybe<Contact>;
  updateCustomer?: Maybe<Customer>;
  updateDocument?: Maybe<Document>;
  updateEmployee?: Maybe<Employee>;
  updateOpportunity?: Maybe<Opportunity>;
  updateOpportunityAssignment?: Maybe<OpportunityAssignment>;
  updateOpportunityAssignmentProposal?: Maybe<OpportunityAssignmentProposal>;
  updateProject?: Maybe<Project>;
};

export type MutationCloseOpportunityArgs = {
  closeReason: OpportunityCloseReasonInput;
  id: Scalars['ID']['input'];
};

export type MutationCreateContactArgs = {
  contact: ContactInput;
};

export type MutationCreateCustomerArgs = {
  customer: CustomerInput;
};

export type MutationCreateDocumentArgs = {
  document?: InputMaybe<DocumentInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreateLabelArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<LabelInput>;
};

export type MutationCreateOpportunityArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  opportunity: OpportunityInput;
};

export type MutationCreateOpportunityAssignmentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  opportunityAssignment: OpportunityAssignmentInput;
};

export type MutationCreateOpportunityAssignmentProposalArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  opportunityAssignmentProposal: OpportunityAssignmentProposalInput;
};

export type MutationCreateSkillArgs = {
  skill: SkillInput;
};

export type MutationDeleteDocumentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteOpportunityArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteOpportunityAssignmentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteOpportunityAssignmentProposalArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteSkillArgs = {
  skill: SkillInput;
};

export type MutationMarkOpportunityAsWonArgs = {
  id: Scalars['ID']['input'];
  projectWonId: Scalars['ID']['input'];
};

export type MutationMergeSkillsArgs = {
  skillMerge: SkillMergeInput;
};

export type MutationReopenOpportunityArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRequestProjectArgs = {
  projectRequest: ProjectRequestInput;
};

export type MutationUpdateAccountArgs = {
  user: AccountInput;
};

export type MutationUpdateContactArgs = {
  contact: ContactInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateCustomerArgs = {
  customer: CustomerInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateDocumentArgs = {
  document?: InputMaybe<DocumentInput>;
  id: Scalars['ID']['input'];
};

export type MutationUpdateEmployeeArgs = {
  employee: EmployeeInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateOpportunityArgs = {
  id: Scalars['ID']['input'];
  opportunity: OpportunityInput;
};

export type MutationUpdateOpportunityAssignmentArgs = {
  id: Scalars['ID']['input'];
  opportunityAssignment: OpportunityAssignmentInput;
};

export type MutationUpdateOpportunityAssignmentProposalArgs = {
  id: Scalars['ID']['input'];
  opportunityAssignmentProposal: OpportunityAssignmentProposalInput;
};

export type MutationUpdateProjectArgs = {
  id: Scalars['ID']['input'];
  project: ProjectInput;
};

export type Opportunity = {
  __typename?: 'Opportunity';
  activities?: Maybe<Array<Maybe<OpportunityActivity>>>;
  assignments?: Maybe<Array<Maybe<OpportunityAssignment>>>;
  billingType?: Maybe<OpportunityBillingType>;
  budget?: Maybe<Scalars['Float']['output']>;
  chanceOfOccurrence?: Maybe<Scalars['Int']['output']>;
  closeReason?: Maybe<OpportunityCloseReason>;
  contacts?: Maybe<Array<Maybe<OpportunityContact>>>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['DateTime']['output']>;
  crmReference?: Maybe<CrmReference>;
  customer?: Maybe<Customer>;
  customerLocation?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  forecastStats?: Maybe<ForecastStats>;
  forecasts?: Maybe<Array<Maybe<Forecast>>>;
  from: Scalars['Date']['output'];
  hasDpa?: Maybe<Scalars['Boolean']['output']>;
  hasNda?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  informationClassification?: Maybe<OpportunityInformationClassification>;
  labels?: Maybe<Array<Maybe<Label>>>;
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['DateTime']['output']>;
  linkDpa?: Maybe<Scalars['String']['output']>;
  linkNda?: Maybe<Scalars['String']['output']>;
  linkOffer?: Maybe<Scalars['String']['output']>;
  linkSalesForce?: Maybe<Scalars['String']['output']>;
  linkTenderDocuments?: Maybe<Scalars['String']['output']>;
  links?: Maybe<Array<Maybe<Link>>>;
  name: Scalars['String']['output'];
  personDays?: Maybe<Scalars['Float']['output']>;
  postponedDate?: Maybe<Scalars['Date']['output']>;
  projectEnablers?: Maybe<Array<Maybe<Employee>>>;
  projectManagers?: Maybe<Array<Maybe<Employee>>>;
  projectWon?: Maybe<Project>;
  /** @deprecated Use `projectWon` */
  projectWonId?: Maybe<Scalars['ID']['output']>;
  remoteWorkPercentage?: Maybe<Scalars['Int']['output']>;
  requestedAsProject?: Maybe<Scalars['Boolean']['output']>;
  revenuePotential?: Maybe<OpportunityRevenuePotential>;
  salesAccountManager?: Maybe<Employee>;
  state?: Maybe<OpportunityState>;
  stateChangedDate?: Maybe<Scalars['DateTime']['output']>;
  supporters?: Maybe<Array<Maybe<Employee>>>;
  teams?: Maybe<Array<Maybe<Team>>>;
  technicalAccountManagers?: Maybe<Array<Maybe<Employee>>>;
  to?: Maybe<Scalars['Date']['output']>;
};

export type OpportunityAssignmentsArgs = {
  sort?: InputMaybe<Array<InputMaybe<OpportunityAssignmentSortInput>>>;
};

export type OpportunityForecastsArgs = {
  filter: YearMonthRangeInput;
};

export type OpportunityRevenuePotentialArgs = {
  interval?: InputMaybe<IntervalInput>;
  month?: InputMaybe<Scalars['YearMonth']['input']>;
  teamFilter?: InputMaybe<OpportunityRevenuePotentialTeamFilterGraphQlInputType>;
};

export type OpportunityActivity = {
  __typename?: 'OpportunityActivity';
  changes: Array<Maybe<Change>>;
  eventType: Scalars['String']['output'];
  recordedAt: Scalars['DateTime']['output'];
  recordedBy: Employee;
  reference?: Maybe<Reference>;
};

export type OpportunityAssignment = {
  __typename?: 'OpportunityAssignment';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['DateTime']['output']>;
  dailyRate?: Maybe<Scalars['Float']['output']>;
  employee?: Maybe<Employee>;
  /**  if from is null, the from value of the opportunity is inherited */
  from?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['DateTime']['output']>;
  opportunity?: Maybe<Opportunity>;
  priceGroup?: Maybe<PriceGroup>;
  proposals?: Maybe<Array<Maybe<OpportunityAssignmentProposal>>>;
  skills?: Maybe<Array<Maybe<RatedSkill>>>;
  /**  if to is null, the to value of the opportunity is inherited */
  to?: Maybe<Scalars['Date']['output']>;
  utilization?: Maybe<Scalars['Float']['output']>;
};

export type OpportunityAssignmentProposalsArgs = {
  sort?: InputMaybe<Array<InputMaybe<OpportunityAssignmentProposalSortInput>>>;
};

export type OpportunityAssignmentInput = {
  dailyRate?: InputMaybe<Scalars['Float']['input']>;
  employeeId?: InputMaybe<Scalars['ID']['input']>;
  from?: InputMaybe<Scalars['Date']['input']>;
  opportunityId: Scalars['ID']['input'];
  priceGroup?: InputMaybe<PriceGroup>;
  skills?: InputMaybe<Array<InputMaybe<RatedSkillInput>>>;
  to?: InputMaybe<Scalars['Date']['input']>;
  utilization: Scalars['Float']['input'];
};

export type OpportunityAssignmentProposal = {
  __typename?: 'OpportunityAssignmentProposal';
  assignment?: Maybe<OpportunityAssignment>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['DateTime']['output']>;
  dailyRate?: Maybe<Scalars['Float']['output']>;
  employee: Employee;
  from?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['DateTime']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  priceGroup?: Maybe<PriceGroup>;
  to?: Maybe<Scalars['Date']['output']>;
  utilization?: Maybe<Scalars['Float']['output']>;
};

export type OpportunityAssignmentProposalInput = {
  dailyRate?: InputMaybe<Scalars['Float']['input']>;
  employeeId: Scalars['ID']['input'];
  from?: InputMaybe<Scalars['Date']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  opportunityAssignmentId: Scalars['ID']['input'];
  priceGroup?: InputMaybe<PriceGroup>;
  to?: InputMaybe<Scalars['Date']['input']>;
  utilization?: InputMaybe<Scalars['Float']['input']>;
};

export enum OpportunityAssignmentProposalSortField {
  CreatedDate = 'CREATED_DATE',
  LastModifiedDate = 'LAST_MODIFIED_DATE',
}

export type OpportunityAssignmentProposalSortInput = {
  field: OpportunityAssignmentProposalSortField;
  order?: InputMaybe<SortOrder>;
};

export enum OpportunityAssignmentSortField {
  CreatedDate = 'CREATED_DATE',
  LastModifiedDate = 'LAST_MODIFIED_DATE',
}

export type OpportunityAssignmentSortInput = {
  field: OpportunityAssignmentSortField;
  order?: InputMaybe<SortOrder>;
};

export enum OpportunityBillingType {
  Fixed = 'FIXED',
  Hourly = 'HOURLY',
}

export type OpportunityCloseReason = {
  __typename?: 'OpportunityCloseReason';
  customText?: Maybe<Scalars['String']['output']>;
  type?: Maybe<CloseReason>;
};

export type OpportunityCloseReasonInput = {
  customText?: InputMaybe<Scalars['String']['input']>;
  type: CloseReason;
};

export type OpportunityContact = {
  __typename?: 'OpportunityContact';
  contact?: Maybe<Contact>;
  contactTypes?: Maybe<Array<Maybe<ContactType>>>;
};

export type OpportunityContactInput = {
  contactId: Scalars['ID']['input'];
  contactTypes: Array<InputMaybe<ContactType>>;
};

export type OpportunityFilterCriteriaInput = {
  key: OpportunityFilterKeys;
  operator: CriteriaOperator;
  value?: InputMaybe<Scalars['String']['input']>;
};

/**  Advanced Filter Inputs */
export enum OpportunityFilterKeys {
  AssignmentIds = 'ASSIGNMENT_IDS',
  Budget = 'BUDGET',
  ChanceOfOccurrence = 'CHANCE_OF_OCCURRENCE',
  ContributorIds = 'CONTRIBUTOR_IDS',
  CreatedDate = 'CREATED_DATE',
  Customer = 'CUSTOMER',
  Description = 'DESCRIPTION',
  FromDate = 'FROM_DATE',
  HasProject = 'HAS_PROJECT',
  InformationClassification = 'INFORMATION_CLASSIFICATION',
  LabelIds = 'LABEL_IDS',
  Name = 'NAME',
  Nda = 'NDA',
  ProposalIds = 'PROPOSAL_IDS',
  SalesAccountManager = 'SALES_ACCOUNT_MANAGER',
  SkillIds = 'SKILL_IDS',
  State = 'STATE',
  SupporterIds = 'SUPPORTER_IDS',
  TeamIds = 'TEAM_IDS',
  TechnicalAccountManagers = 'TECHNICAL_ACCOUNT_MANAGERS',
  ToDate = 'TO_DATE',
}

export type OpportunityFilterNodeInput = {
  children?: InputMaybe<Array<InputMaybe<OpportunityFilterNodeInput>>>;
  type: FilterNodeType;
  value?: InputMaybe<OpportunityFilterCriteriaInput>;
};

export enum OpportunityInformationClassification {
  Confidential = 'CONFIDENTIAL',
  Internal = 'INTERNAL',
  Restricted = 'RESTRICTED',
}

export type OpportunityInput = {
  assignments?: InputMaybe<Array<InputMaybe<OpportunityAssignmentInput>>>;
  billingType?: InputMaybe<OpportunityBillingType>;
  budget?: InputMaybe<Scalars['Float']['input']>;
  chanceOfOccurrence?: InputMaybe<Scalars['Int']['input']>;
  contacts?: InputMaybe<Array<InputMaybe<OpportunityContactInput>>>;
  customerId: Scalars['ID']['input'];
  customerLocation?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  from: Scalars['Date']['input'];
  hasDpa?: InputMaybe<Scalars['Boolean']['input']>;
  hasNda?: InputMaybe<Scalars['Boolean']['input']>;
  informationClassification?: InputMaybe<OpportunityInformationClassification>;
  labelIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  linkDpa?: InputMaybe<Scalars['String']['input']>;
  linkNda?: InputMaybe<Scalars['String']['input']>;
  linkOffer?: InputMaybe<Scalars['String']['input']>;
  linkSalesForce?: InputMaybe<Scalars['String']['input']>;
  linkTenderDocuments?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  personDays?: InputMaybe<Scalars['Float']['input']>;
  postponedDate?: InputMaybe<Scalars['Date']['input']>;
  projectEnablerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  projectManagerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  remoteWorkPercentage?: InputMaybe<Scalars['Int']['input']>;
  salesAccountManagerId?: InputMaybe<Scalars['ID']['input']>;
  supporterIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  teamIds: Array<InputMaybe<Scalars['ID']['input']>>;
  technicalAccountManagerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  to?: InputMaybe<Scalars['Date']['input']>;
};

export enum OpportunityNullFilterField {
  Assignments = 'ASSIGNMENTS',
  Contributors = 'CONTRIBUTORS',
  ProjectEnablers = 'PROJECT_ENABLERS',
  ProjectManagers = 'PROJECT_MANAGERS',
  SalesAccountManager = 'SALES_ACCOUNT_MANAGER',
  Supporters = 'SUPPORTERS',
  TechnicalAccountManagers = 'TECHNICAL_ACCOUNT_MANAGERS',
}

export type OpportunityRevenuePotential = {
  __typename?: 'OpportunityRevenuePotential';
  calculationTime?: Maybe<Scalars['DateTime']['output']>;
  hint?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type OpportunityRevenuePotentialTeamFilterGraphQlInputType = {
  includeDemands?: InputMaybe<Scalars['Boolean']['input']>;
  teamIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export enum OpportunitySearchField {
  Customer = 'CUSTOMER',
  Description = 'DESCRIPTION',
  Labels = 'LABELS',
  Name = 'NAME',
}

export type OpportunitySearchInput = {
  expression: Scalars['String']['input'];
  fields: Array<InputMaybe<OpportunitySearchField>>;
};

export enum OpportunitySortField {
  Budget = 'BUDGET',
  ChanceOfOccurrence = 'CHANCE_OF_OCCURRENCE',
  CreatedDate = 'CREATED_DATE',
  Customer = 'CUSTOMER',
  LastModifiedDate = 'LAST_MODIFIED_DATE',
  Name = 'NAME',
  SalesAccountManager = 'SALES_ACCOUNT_MANAGER',
  StartDate = 'START_DATE',
  State = 'STATE',
}

export type OpportunitySortInput = {
  field: OpportunitySortField;
  order?: InputMaybe<SortOrder>;
};

export enum OpportunityState {
  Closed = 'CLOSED',
  Open = 'OPEN',
  Won = 'WON',
}

export type Poliday = {
  __typename?: 'Poliday';
  date?: Maybe<Scalars['Date']['output']>;
  isHalfDay?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  states?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type PolidayFilterInput = {
  years?: InputMaybe<IntRangeInput>;
};

export enum PriceGroup {
  Advanced = 'ADVANCED',
  Architect = 'ARCHITECT',
  Junior = 'JUNIOR',
  Senior = 'SENIOR',
  SpecialPrice = 'SPECIAL_PRICE',
}

export type Project = {
  __typename?: 'Project';
  assignments?: Maybe<Array<Maybe<ProjectAssignment>>>;
  billingType?: Maybe<BillingType>;
  budget?: Maybe<Scalars['Float']['output']>;
  chanceOfOccurrence?: Maybe<Scalars['Int']['output']>;
  completedDate?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['DateTime']['output']>;
  customer?: Maybe<Customer>;
  description?: Maybe<Scalars['String']['output']>;
  forecastStats?: Maybe<ForecastStats>;
  forecasts?: Maybe<Array<Maybe<Forecast>>>;
  from?: Maybe<Scalars['Date']['output']>;
  hasNda: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  informationClassification?: Maybe<InformationClassification>;
  labels?: Maybe<Array<Maybe<Label>>>;
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['DateTime']['output']>;
  linkNda?: Maybe<Scalars['String']['output']>;
  linkOffer?: Maybe<Scalars['String']['output']>;
  linkOrder?: Maybe<Scalars['String']['output']>;
  links?: Maybe<Array<Maybe<Link>>>;
  name: Scalars['String']['output'];
  personDays?: Maybe<Scalars['Float']['output']>;
  salesAccountManager?: Maybe<Employee>;
  sourceOpportunities?: Maybe<Array<Maybe<Opportunity>>>;
  state?: Maybe<ProjectState>;
  team?: Maybe<Team>;
  technicalAccountManagers?: Maybe<Array<Maybe<Employee>>>;
  to?: Maybe<Scalars['Date']['output']>;
  workLogStats?: Maybe<ProjectWorkLogStats>;
  workLogs?: Maybe<Array<Maybe<ProjectWorkLog>>>;
  zepId: Scalars['ID']['output'];
};

export type ProjectForecastsArgs = {
  filter: YearMonthRangeInput;
};

export type ProjectWorkLogsArgs = {
  filter: YearMonthRangeInput;
};

export type ProjectAssignment = {
  __typename?: 'ProjectAssignment';
  availability: Scalars['Int']['output'];
  dailyRate?: Maybe<Scalars['Float']['output']>;
  employee?: Maybe<Employee>;
  from?: Maybe<Scalars['Date']['output']>;
  project?: Maybe<Project>;
  projectEnabler?: Maybe<Scalars['Boolean']['output']>;
  projectManager?: Maybe<Scalars['Boolean']['output']>;
  to?: Maybe<Scalars['Date']['output']>;
};

export type ProjectAssignmentInput = {
  dailyRate?: InputMaybe<Scalars['Float']['input']>;
  employeeId?: InputMaybe<Scalars['ID']['input']>;
  from: Scalars['Date']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  skills?: InputMaybe<Array<InputMaybe<RatedSkillInput>>>;
  to: Scalars['Date']['input'];
  utilization: Scalars['Int']['input'];
};

export type ProjectContact = {
  contactTypes?: InputMaybe<Array<InputMaybe<ContactType>>>;
  customerId: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  salutation?: InputMaybe<SalutationEnum>;
  telephone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectFilterCriteriaInput = {
  key: ProjectFilterKey;
  operator: CriteriaOperator;
  value: Scalars['String']['input'];
};

/**  Advanced Filter Inputs */
export enum ProjectFilterKey {
  Assignments = 'ASSIGNMENTS',
  Budget = 'BUDGET',
  Contributors = 'CONTRIBUTORS',
  Customer = 'CUSTOMER',
  Description = 'DESCRIPTION',
  FromDate = 'FROM_DATE',
  InformationClassification = 'INFORMATION_CLASSIFICATION',
  Labels = 'LABELS',
  Name = 'NAME',
  Nda = 'NDA',
  State = 'STATE',
  Team = 'TEAM',
  ToDate = 'TO_DATE',
  Type = 'TYPE',
}

export type ProjectFilterNodeInput = {
  children?: InputMaybe<Array<InputMaybe<ProjectFilterNodeInput>>>;
  type: FilterNodeType;
  value?: InputMaybe<ProjectFilterCriteriaInput>;
};

export type ProjectInput = {
  informationClassification?: InputMaybe<InformationClassification>;
  labelIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  technicalAccountManagerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type ProjectRequestInput = {
  assignments: Array<InputMaybe<ProjectAssignmentInput>>;
  billingType: OpportunityBillingType;
  budget?: InputMaybe<Scalars['Float']['input']>;
  contacts: Array<ProjectContact>;
  customer: CustomerInput;
  description?: InputMaybe<Scalars['String']['input']>;
  from: Scalars['Date']['input'];
  hasDpa?: InputMaybe<Scalars['Boolean']['input']>;
  hasNda?: InputMaybe<Scalars['Boolean']['input']>;
  labelIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  linkDpa?: InputMaybe<Scalars['String']['input']>;
  linkNda?: InputMaybe<Scalars['String']['input']>;
  linkOffer: Scalars['String']['input'];
  linkOrder?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  personDays: Scalars['Float']['input'];
  projectManagerIds: Array<Scalars['ID']['input']>;
  salesAccountManagerId: Scalars['ID']['input'];
  sourceOpportunityId: Scalars['ID']['input'];
  sourceOpportunityUrl: Scalars['String']['input'];
  teamId: Scalars['ID']['input'];
  technicalAccountManagerIds: Array<Scalars['ID']['input']>;
  to: Scalars['Date']['input'];
};

export enum ProjectSearchField {
  Customer = 'CUSTOMER',
  Description = 'DESCRIPTION',
  Name = 'NAME',
}

export type ProjectSearchInput = {
  expression: Scalars['String']['input'];
  fields: Array<InputMaybe<ProjectSearchField>>;
};

export enum ProjectSortField {
  Budget = 'BUDGET',
  Customer = 'CUSTOMER',
  LastModifiedDate = 'LAST_MODIFIED_DATE',
  Name = 'NAME',
  SalesAccountManager = 'SALES_ACCOUNT_MANAGER',
  StartDate = 'START_DATE',
}

export type ProjectSortInput = {
  field: ProjectSortField;
  order?: InputMaybe<SortOrder>;
};

export enum ProjectState {
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  Deferred = 'DEFERRED',
  Email = 'EMAIL',
  Missing = 'MISSING',
  Verbal = 'VERBAL',
  Written = 'WRITTEN',
}

export enum ProjectType {
  External = 'EXTERNAL',
  Internal = 'INTERNAL',
}

export type ProjectWorkLog = {
  __typename?: 'ProjectWorkLog';
  busyHours?: Maybe<Scalars['Float']['output']>;
  month?: Maybe<Scalars['YearMonth']['output']>;
  revenue?: Maybe<Scalars['Float']['output']>;
};

export type ProjectWorkLogStats = {
  __typename?: 'ProjectWorkLogStats';
  revenue?: Maybe<Scalars['Float']['output']>;
  revenueHint?: Maybe<Scalars['String']['output']>;
  updateTime?: Maybe<Scalars['DateTime']['output']>;
};

export type Query = {
  __typename?: 'Query';
  account?: Maybe<Account>;
  customer?: Maybe<Customer>;
  customers?: Maybe<Array<Maybe<Customer>>>;
  document?: Maybe<Document>;
  documents?: Maybe<Array<Maybe<Document>>>;
  employee?: Maybe<Employee>;
  employeeForecast?: Maybe<EmployeeForecast>;
  employeeForecasts?: Maybe<Array<Maybe<EmployeeForecast>>>;
  employmentStatuses?: Maybe<Array<Maybe<EmploymentStatus>>>;
  forecastReports?: Maybe<Array<Maybe<ForecastReport>>>;
  forecasts?: Maybe<Array<Maybe<Forecast>>>;
  label?: Maybe<Label>;
  labels?: Maybe<Array<Maybe<Label>>>;
  locations?: Maybe<Array<Maybe<Location>>>;
  opportunity?: Maybe<Opportunity>;
  polidays?: Maybe<Array<Maybe<Poliday>>>;
  project?: Maybe<Project>;
  queryEmployees?: Maybe<Array<Maybe<Employee>>>;
  queryOpportunities?: Maybe<Array<Maybe<Opportunity>>>;
  queryProjects?: Maybe<Array<Maybe<Project>>>;
  skills?: Maybe<Array<Maybe<AggregateSkill>>>;
  teams?: Maybe<Array<Maybe<Team>>>;
};

export type QueryCustomerArgs = {
  id: Scalars['ID']['input'];
};

export type QueryDocumentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryDocumentsArgs = {
  filter?: InputMaybe<DocumentFilterInput>;
};

export type QueryEmployeeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryEmployeeForecastArgs = {
  employeeId: Scalars['ID']['input'];
  month: Scalars['YearMonth']['input'];
};

export type QueryEmployeeForecastsArgs = {
  filter?: InputMaybe<EmployeeForecastFilterInput>;
};

export type QueryForecastReportsArgs = {
  filter?: InputMaybe<ForecastReportFilterInput>;
};

export type QueryForecastsArgs = {
  filter?: InputMaybe<ForecastFilterInput>;
};

export type QueryLabelArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpportunityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPolidaysArgs = {
  filter?: InputMaybe<PolidayFilterInput>;
};

export type QueryProjectArgs = {
  id: Scalars['ID']['input'];
};

export type QueryQueryEmployeesArgs = {
  filter?: InputMaybe<EmployeeFilterNodeInput>;
  sort?: InputMaybe<Array<InputMaybe<EmployeeSortInput>>>;
};

export type QueryQueryOpportunitiesArgs = {
  filter?: InputMaybe<OpportunityFilterNodeInput>;
  sort?: InputMaybe<Array<InputMaybe<OpportunitySortInput>>>;
};

export type QueryQueryProjectsArgs = {
  filter?: InputMaybe<ProjectFilterNodeInput>;
  sort?: InputMaybe<Array<InputMaybe<ProjectSortInput>>>;
};

export type QuerySkillsArgs = {
  filter?: InputMaybe<SkillFilterInput>;
};

export type QueryTeamsArgs = {
  filter?: InputMaybe<TeamFilterInput>;
};

export type RatedSkill = {
  __typename?: 'RatedSkill';
  name?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<Scalars['Int']['output']>;
};

export type RatedSkillInput = {
  name: Scalars['String']['input'];
  rating: Scalars['Int']['input'];
};

export type Reference = {
  __typename?: 'Reference';
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export enum SalutationEnum {
  Female = 'FEMALE',
  Male = 'MALE',
  NonBinary = 'NON_BINARY',
}

export type Skill = {
  __typename?: 'Skill';
  /**  the timestamp of the skill creation */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /**  the description of the skill if present */
  description?: Maybe<Scalars['String']['output']>;
  /**  the name of the skill */
  name?: Maybe<Scalars['String']['output']>;
};

/**  filter parameter for the skills query */
export type SkillFilterInput = {
  /**  the search can be used for a text search among a specified collection of fields */
  search?: InputMaybe<SkillSearchInput>;
};

export type SkillInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type SkillMergeInput = {
  mergedDescription?: InputMaybe<Scalars['String']['input']>;
  mergedName?: InputMaybe<Scalars['String']['input']>;
  synonyms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/**  the fields within a skill that can be used for the search expression */
export enum SkillSearchField {
  /**  the name of the skill */
  Name = 'NAME',
}

/**  the search filter for skills */
export type SkillSearchInput = {
  /**  the search expression */
  expression: Scalars['String']['input'];
  /**  the collection of fields that should be considered */
  fields: Array<InputMaybe<SkillSearchField>>;
};

export type SkillsFilterInput = {
  operator: FilterOperator;
  skills: Array<InputMaybe<Scalars['String']['input']>>;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type Team = {
  __typename?: 'Team';
  archived?: Maybe<Scalars['Boolean']['output']>;
  billable?: Maybe<Scalars['Boolean']['output']>;
  hasMeetingRoom?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  lob?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  parent?: Maybe<Team>;
  shortName?: Maybe<Scalars['String']['output']>;
};

export type TeamFilterInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  lob?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TeamInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  login: Scalars['String']['output'];
};

export type YearMonthRangeInput = {
  max?: InputMaybe<Scalars['YearMonth']['input']>;
  min?: InputMaybe<Scalars['YearMonth']['input']>;
};
